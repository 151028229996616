import React from "react";
import { StarRating4pt7, STEEZYLogo } from "app/components/Icon";
import styles from "./styles.module.scss";

export function LogoRatings() {
  return (
    <div className={styles.container}>
      <div className={styles.flexContainer}>
        <STEEZYLogo className={styles.STEEZYLogo} />
        <div style={{ display: "flex", gap: "4px" }}>
          <StarRating4pt7 className={styles.stars} />
          <p className={styles.ratings}>4.7 • 7K+ ratings</p>
        </div>
      </div>
    </div>
  );
}
